import { LitElement, html, css, nothing } from 'lit';
import { MDuesPage } from '../components/mdues_page.js';
import { repeat } from 'lit/directives/repeat.js';
import { guard } from 'lit/directives/guard.js';
import '@material/mwc-icon';
import '@material/mwc-icon';
import '@material/mwc-button';
import '@material/mwc-menu';
import '@material/mwc-dialog';
import '@material/mwc-textfield';
import '@material/mwc-checkbox';
import '@material/mwc-linear-progress';
import {dayjs} from '../shared-components/utilities/dates.js';
2693
import { ProgressCircle } from '../shared-components/progress-circle.js';

import { client, formatQueryError, refetch_searches, local_fields, increase_fields, EditIncrease, EditImportFile } from '../queries/queries.js';
import gql from 'graphql-tag';


import '../shared-components/form.js';
import '../components/editors.js';
import { debounce } from '../shared-components/utilities/debounce.js';
import * as Comlink from 'comlink';
import { wait } from '../shared-components/utilities/anim.js';
import { validate } from 'graphql';

const jsdate = (d) => {
  return d ? new EventDate(new Date(d.y, d.m - 1, d.d)) : null;
}

const xlsxdate = (data) => {
  return jsdate(XLSX.SSF.parse_date_code(data));
}

const isnum = n => n !== null && n !== undefined && !isNaN(Number(String(n).replace(/[^\d.]/g, '')));

let _stringer_memoize = new Map();
const stringerize = s => {
  if (_stringer_memoize.has(s)) return _stringer_memoize.get(s);
  const res = s.toLowerCase().replace(/[^a-z0-9 ]/, '').split(' ').map(x => x.trim()).join(' ');
  _stringer_memoize.set(s, res);
  return res;
}

let sort_memo = new Map();
const alphasort = (s) => {
  if (sort_memo.has(s)) return sort_memo.get(s);
  const ret = s.split(' ').map(a => a.trim()).filter(a => a && a!=='').sort((a,b) => {
    if(a < b) { return -1; }
    if(a > b) { return 1; }
    return 0;
  }).join(' ');
  sort_memo.set(s, ret);
  return ret;
}

const initMatrix = (s1, s2) => {
  /* istanbul ignore next */
  if (undefined == s1 || undefined == s2) {
    return null;
  }

  let d = [];
  for (let i = 0; i <= s1.length; i++) {
    d[i] = [];
    d[i][0] = i;
  }
  for (let j = 0; j <= s2.length; j++) {
    d[0][j] = j;
  }

  return d;
};

const damerau = (i, j, s1, s2, d, cost) => {
  if (i > 1 && j > 1 && s1[i - 1] === s2[j - 2] && s1[i - 2] === s2[j - 1]) {
    d[i][j] = Math.min.apply(null, [d[i][j], d[i - 2][j - 2] + cost]);
  }
};

const distance_damerau = (s1, s2) => {
  if (
    undefined == s1 ||
    undefined == s2 ||
    "string" !== typeof s1 ||
    "string" !== typeof s2
  ) {
    return -1;
  }

  let d = initMatrix(s1, s2);
  /* istanbul ignore next */
  if (null === d) {
    return -1;
  }
  for (var i = 1; i <= s1.length; i++) {
    let cost;
    for (let j = 1; j <= s2.length; j++) {
      if (s1.charAt(i - 1) === s2.charAt(j - 1)) {
        cost = 0;
      } else {
        cost = 1;
      }

      d[i][j] = Math.min.apply(null, [
        d[i - 1][j] + 1,
        d[i][j - 1] + 1,
        d[i - 1][j - 1] + cost,
      ]);

      damerau(i, j, s1, s2, d, cost);
    }
  }

  return d[s1.length][s2.length];
};

const similarity_actual = (s1, s2) => {
  if (s1 === s2) return 1.0;
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }

 // return (longerLength - editDist(longer, shorter)) / parseFloat(longerLength);
  return (longerLength - distance_damerau(longer, shorter)) / parseFloat(longerLength);
}
let sim_memo = new Map();
const similarity = (s1, s2, debug) => {
  if (debug) console.warn(`RAW sim strings: '${s1}' vs '${s2}, stringerized: '${stringerize(s1)}' : '${stringerize(s2)}'`);
  s1 = alphasort(stringerize(s1 || ''));
  s2 = alphasort(stringerize(s2 || ''));
  const key = `${s1}+${s2}`;
  if (sim_memo.has(key)){
    const result = sim_memo.get(key);
    if (debug) console.log(`CACHED similarity("${s1}", "${s2}") = ${result} [key="${key}"]`)
    return result;
  }
  const result = similarity_actual(s1, s2);
  sim_memo.set(key, result);
  if (debug) console.log(`similarity("${s1}", "${s2}") = ${result} [key="${key}"]`)
  return result;
}





const arcDraw = (radius, cx, cy, pct) => {
  pct = pct === undefined ? 0 : pct;
  pct = pct === 1 ? 0.999999 : pct;
  let phi = (Math.PI / 2) * 3 - pct * Math.PI * 2;
  return `M ${cx},${cy + radius} A ${radius} ${radius} 0 ${pct <= 0.5 ? 0 : 1},1 ${cx + radius * Math.cos(phi)} ${cy - radius * Math.sin(phi)}`;
}
const alpha_sort = (a, b) => {
  a = a && a.trim ? a.trim() : a;
  b = b && b.trim ? b.trim() : b;
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}
const contact_sort = (a,b) => alpha_sort(a?.name, b?.name);

const numeric_sort = (a, b) => Number(a) - Number(b);
const date_sort = (a, b) => new Date(a) - new Date(b);

const sorts = {
  'row_number': numeric_sort,
  'employer': alpha_sort,
  'subunit': numeric_sort, // works for bools
  'master': alpha_sort,
  'local': numeric_sort,
  'council': numeric_sort,
  'agreement_effective': date_sort,
  'agreement_expires': date_sort,
  'members': numeric_sort,
  'pct': numeric_sort,
  'cents_hourly': numeric_sort,
  'hourly_base': numeric_sort,
  'annual': numeric_sort,
  'annual_base': numeric_sort,
  'effective': date_sort,
  'negotiations': numeric_sort,
  'comments': alpha_sort,
  'contact': contact_sort
}
 const formatErrors = errs => {
                const first = errs?.[0];
                const remainder = errs?.slice(1);
                return first ? html`
                <div class='errors' title=${errs?.map?.(e => `[${e}]`)?.join?.('\n') || 'no errors'}>
                  <span class="err">${first}</span>${remainder && remainder.length > 0 ? html`<span class="err_more">&hellip;+${remainder.length}</span>` : nothing}
                </div>
                ` : nothing;
              }


// const cell_match = (row, header) => row.match_fields && row.match_fields[header.short] && row.match_fields[header.short].match_score;
// const cell_match_title_info = (row, header) => row.match_fields && row.match_fields[header.short] ? `${header.long}: ${row[header.short] || 'none'} ⇨ ${row.match_fields[header.short].comparing[1] || 'none'} [${Math.round(row.match_fields[header.short].val * 100)}%]` : null;


const import_export_page_styles = css`
       
      mwc-button {
        --mdc-theme-on-primary: white;
        --mdc-theme-primary: var(--paper-pink-a200);
        --mdc-theme-on-secondary: white;
        --mdc-theme-secondary: var(--paper-pink-a200);
      }

      .column {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column; 
        width: 100%;
        padding: 0;
        padding-top: 0;
        height: 100%;
      }
      .column > * {
        margin-top: 24px;
      }
      .load_message {
          padding-bottom: 18px;

        }

      .drag_placeholder {
          text-align: center;
          opacity: 0.4;
          box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; 
        width: 100%;
        height: 100%;

        }

        .scroller {
          overflow: overlay;
          height: calc(100vh - 64px);
          width: 100%;
        }

        .content-area {
          flex: 1;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;

          height: calc(100vh - 64px);
        }

        mwc-fab {
          position: static;
        }
        #fab-holder {
          width: 100%;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: flex-end;
          flex-direction: row;
        }

        .primary {
          font-weight: 500;
          font-size: 20px;
          }
        .secondary {
          font-weight: 200;
          font-size: 16px;
          margin-top: 8px;
          }


        mwc-top-app-bar {
          --mdc-theme-primary: var(--paper-green-700);
          background-color: var(--paper-green-700);
        }

        .card {
          box-sizing: border-box;
          background-color: white;
          border: 1px solid var(--paper-grey-400);
          border-radius: 8px;
          transition: var(--shadow-transition);
          height: 100%;
          width: 100%;
          font-size: 12px;
          overflow: hidden;

          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column; 
          margin-bottom: 60px;

        }
        .card > h2 {
          margin: 12px;
          font-size: 90%;
        }

        .card:hover {
          box-shadow: var(--shadow-elevation-8dp_-_box-shadow);
        }

        .card[height_computed] {
          height: fit-content;
        }

        .table-scroller[height_computed] {
          height: fit-content;
        }
              
        .top-app-bar-adjust {
          margin-top: 64px;
          }


        /* TABLE STYLES */
    .table-container { 
        flex: 1 1; 
        box-sizing: border-box; 
        width: 100%; 
        height: 100%;
        position: relative; /*FIXME: this isn't optimal... */ 
        overflow-y: auto; 
        overflow-x: auto;
        max-height: 100%;

        overflow-y: overlay;
        height: calc(100vh - 64px);
        margin: 0;
        }
      .table-scroller { 
        height: intrinsic;           /* Safari/WebKit uses a non-standard name */
        height: -moz-max-content;    /* Firefox/Gecko */
        height: -webkit-max-content; /* Chrome */
        height: max-content;
        width: intrinsic;           /* Safari/WebKit uses a non-standard name */
        width: -moz-max-content;    /* Firefox/Gecko */
        width: -webkit-max-content; /* Chrome */
        width: max-content;
        min-width: 100%;
        padding-bottom: 120px;
        }

        table { box-sizing: border-box; border-collapse: collapse; width: 100%; font-size: 80%}
        td,th { border: none; padding: 6px 12px; whitespace: normal;}
        th {
          text-align: left;
          text-transform: uppercase;
          border: none;
          font-size: 80%;
          background-color: var(--paper-grey-200);
          color: var(--paper-grey-600);
          z-index: 2;
          position: sticky;
          top: 0;
        }
        tr#file_header {

        }

        th[type="number"], th[type="amt"], th[type="pct"] {
          text-align: right;
        }
        th[type="match"], td.match {
          max-width: 130px;
        }

        td.check_status {
          text-align: center;
        }
        td.check_status > mwc-checkbox {
          position: relative;
          right: 12px;
        }
        th > div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row; 
          position: relative;
        }
        th.control {
          padding: 0;
        }

        th.control > div {
          justify-content: center;
        }
        
        th[type="number"] > div, th[type="amt"] > div {
          justify-content: flex-end;
        }
        th[type="number"] span, th[type="amt"] span {
          order: 99;
        }


        th mwc-icon {
          visibility: hidden;
          font-size: 12px;
        }
        th mwc-icon[sort]{
          visibility: visible;
        }
        th mwc-icon[reverse] {
          transform: scaleY(-1);
        }

        th mwc-icon[filtered]{
          visibility: visible;
        }
        th:hover mwc-icon:not([sort]) {
          visibility: visible;
          opacity: 0.45;
        }
        th mwc-menu {
          visibility: hidden;
          position: fixed;
          visibility: visible;
        }
        th:hover mwc-menu {
          visibility: visible;
        }

        th .filter_menu_container {
          position: absolute;
          bottom: 0px;
        }

        .filter_item {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;

        }

        th span {
        }

        th:hover {
          cursor: pointer;
        }

        th.control {
          opacity: 1;
        }


        td[errors] {
        }
        td[errors] .errors {
          font-weight: 900;
          font-size: 95%;
        }
        td[errors] .errors > .err {
          position: absolute;
          display: block;
          width: fit-content;
          background-color: var(--paper-red-800);
          color: white;
          border-radius: 4px;
          padding: 3px 6px;
          margin: 4px -3px;
          margin-right: 3px;
        }

        td[errors] .errors > .err_more {
          color: var(--paper-red-800);
          font-size: 80%;
          font-weight: 900;
        }

        td[errors] .errors > .err[last-child] {
          margin-right: 0;
        }

        mwc-select[duplicate] {
          --mdc-select-fill-color: var(--paper-yellow-700);
        }
        tr[errors] > td {
          background-color: var(--paper-red-100);
          --mdc-select-fill-color: (var(--paper-red-200));
        }
        span.error_count {
          font-weight: 900;
          color: var(--paper-red-800);
        }

        /*
        tr { border: none}
        tr:nth-child(even) { background: #CCC;}
        */

        tr {
          border: none;
          border-bottom: 1px solid var(--paper-grey-400); 
          content-visibility: auto;
          /*contain-intrinsic-size: 72px;*/
        }
        tbody > tr:hover {background-color: var(--paper-yellow-50); cursor: pointer;}
        tr[selected] {background-color: var(--paper-teal-50); cursor: pointer;}
        tr:not([validated]) > td { opacity: 0.5 }
        tr[inprogress] {
          opacity: 0.5
        }
        tr[inprogress] > td {
          /*background-color: var(--paper-purple-100);*/
        }
        tr[unitdialogopen] > td div.match_info {
          color: var(--paper-purple-200);
        }

        td {
        }
        td.textcell, td.contactcell {
          text-align: left;
          max-width: 100px;
        }


        td.textcell > div  {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
        }
        td.textcell span.overflow_ellipsis {
          text-overflow: ellipsis;
          white-space: break-spaces;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
        }
        td.contactcell > div {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        td.textcell[column='employer'] > div {
          white-space: normal;
        }


        td.amtcell, td.datecell, td.textcell, td.numbercell > *
        {
          cursor: auto;
        }

        td.remove { padding: 10px 0px;}
        td.type { 
          text-transform: uppercase;
          font-size: 75%;
          font-weight: bold;
        }

        td.amtcell, td.numbercell {
          text-align: right;
        }
        td.numbercell[column='local'], td.numbercell[column='state'], td.numbercell[column='council'] {
        text-align: center;
      }
        td.boolcell { 
          text-align: center;
        }
        
        td[match="good"] {
          color: var(--paper-green-900);
          --match-color: var(--paper-green-900);
        }
        td[match="poor"] {
          color: var(--paper-yellow-900);
          font-weight: 500;
          --match-color: var(--paper-yellow-900);
        }
        td[match="bad"] {
          color: var(--paper-red-900);
          font-weight: 800;
          --match-color: var(--paper-red-900);
        }

        td[match="sending"] {
          color: var(--paper-purple-700);
          --match-color: var(--paper-purple-700);
        }

        td[match="sending"] mwc-icon {
        }

        td.match {
          font-weight: 900;
        }



        span.currency {
          float: left;
        }
        contrib-datecell[changed], contrib-amtcell[changed] {
        font-style: italic;
        color: var(--paper-green-600); 
        }

        .table_controls {
          background-color: var(--paper-green-300);
          padding: 8px 16px;
          color: white;
          margin: 0;
          box-sizing: border-box;
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          --mdc-theme-primary: var(--paper-teal-700);
          color: var(--paper-teal-900);
        }
        .table_controls[selection] {
        }

        .table_controls > * {
        }

        .table_controls > .info {
          margin-left: 64px;
        }
        .table_controls > h2 {
          flex: 1 1;
          text-align: right;
          margin-right: 64px;
          font-weight: 100;
          font-size: 90%;
        }

        .table_controls .buttons {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;
        }
        .buttons > * {
          margin-right: 12px;
          margin-left: 12px;
        }

        div.non_checkable {
          position: relative;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          font-size: 8px;
          color: var(--label-color);
          opacity: 0;
          text-align: center;
          width: fit-content;
          max-width: 24px;
        }

        div.non_checkable[errors] {
          --label-color: var(--paper-red-900);
          opacity: 1;
        }
        div.non_checkable[imported] {
          --label-color: var(--paper-green-800);
          opacity: 1;
        }
        div.non_checkable[info] {
          --label-color: var(--paper-grey-600);
          opacity: 1;
        }

        div.non_checkable  .errors, div.non_checkable  .imported, div.non_checkable  .deactivated {
          display: block;
          text-transform: uppercase;
        }
        div.non_checkable  mwc-icon {
          display: block;
          font-size: 25px;

        }

        div.match_info {
          display: flex;
          flex-direction: column;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          overflow: visible !important;
          position: relative;
          
        }
        div.match_picker {
          margin-left: 12px;
          flex: 1;
        }
        .match_picker > mwc-select {
          width: 100%;
        }
        span.searching {
          color: var(--paper-purple-600);
          font-weight: 100;
          font-size: 90%;
        }



        div.gauge {
          position: relative;
          width: 24px;
          height: 24px;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          font-size: 10%;
          font-weight: bold;
          z-index: 1;
        }
        .match[match="new"] .gauge {
          color: var(--paper-purple-700);
        }

        div.gauge > span {
          white-space: nowrap;
          text-transform: uppercase;
        }
       
        #navigation {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          padding: 12px;
        }

        #navigation-info {
          margin-right: 12px;
        }
        #navigation:last-child { margin-right : 12px }

        mwc-checkbox[disabled] {
          opacity: 0.5;
        }
        @keyframes spin {
          from {
              transform:rotate(360deg);
          }
          to {
              transform:rotate(0deg);
          }
      }
 
        svg { overflow: visible; }
        circle { opacity: 0.25 }
        path { fill: none; stroke-width: 10px;}
        circle { fill: none; stroke-width: 5px; opacity: 0.3}
        svg[match="good"] { stroke: var(--paper-green-900)}
        svg[match="poor"] { stroke: var(--paper-yellow-900)}
        svg[match="bad"] { stroke: var(--paper-red-900)}
        svg[match="sending"] { 
          stroke: var(--paper-purple-800);
          animation-name: spin;
          animation-duration: 4000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        div.contactcell {
          font-size: 90%;
        }
        div.contactcell div {
          display: inline-block;
          margin-bottom: 10px;
        }

        .contactcell .contactname {
          font-weight: 900;
        }
        .contactcell .contactemail {
          font-weight: 100;
        }
        .contactcell .contactphone {
          font-weight: 100;
        }


        .progress_centered {
          z-index: 50;
          position: fixed;
          top: 50%;
          left: 50%;
          --progress-color: var(--paper-grey-300);
          --progress-bg: var(--paper-green-600);
          --progress-size: 128;
        }

        span.preview {
          display: inline-block;
          color: var(--paper-grey-300);
          background-color: var(--paper-grey-300);
          opacity: 0.5;
        }

       
       
        span.preview.checkbox {
          width: 18px;
          height: 18px;
          margin: 11px;
        }
     
        
        svg.match_circ {
          position: absolute;
          top: 0px;
          left: 0px;
          opacity: 0.8;
        }
        div.preview_holder{
          display: flex;
          width: 100%;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          min-width: fit-content;
          overflow: hidden;
        }
        span.preview.circle {
          flex: 0 0 auto;
          width: 48px;
          height: 48px;
          min-width: 48px;
          min-height: 48px;
          border-radius: 100%;
        }


        span.preview.large {
          box-sizing: border-box;
          flex: 1 0 auto;
          font-size: 1rem;
          margin-left: 12px;
          padding: 16px 52px 16px 16px; 
          max-height: 56px;
          min-height: 56px;
          white-space: nowrap;
        } 
        div.upload_error_count {
          color: var(--paper-red-800);
          font-size: 10px;
          font-weight: 100;
        }


        div.progress_overlay {
          position: fixed;
          height: calc(100vh - 250px);
          width: 100%;
          display: flex;
          flex-direction: column;
          align-content: center;
          align-items: center;
          justify-content: center;
        }


        div.progress_overlay > div.progress_block {
          width: 50%;
          margin: 20px;
        }

        div.progress_block > span {
          margin-bottom: 12px;
          color: var(--paper-grey-600);
        }

        span.progress_title{
          text-transform: uppercase;

        }
        span.progress_numbers {
          float: right;
          }
          /*
        span.progress_numbers:before { content: '(' }
        span.progress_numbers:after { content: ')' }
        */


        span.editicon {
          opacity: 0;
          font-family: "Material Icons";
          position: absolute;
          top: 4px;
          left: -20px;
        }

        div.match_info:hover span.editicon {
          opacity: 1;
        }

        mwc-dialog.unit-picker-dialog {
          --mdc-dialog-min-width: min(95vw, 1100px);
          --mdc-dialog-max-width: max(95vw, 1200px);
        }
        mwc-dialog.unit-picker-dialog mwc-select {
          width: 100%;
        }
        mwc-dialog.unit-picker-dialog th {
          background: none;
          color: var(--paper-grey-800);
          max-width: 250px;
        }
        mwc-dialog.unit-picker-dialog th:first-child {
          width: 100%;
        }
        mwc-dialog.unit-picker-dialog tr {
          border: none;
        }
        mwc-dialog.unit-picker-dialog tr[selection] {
        }
        mwc-dialog.unit-picker-dialog tr:hover {
          background-color: var(--paper-cyan-100);
        }
        mwc-dialog.unit-picker-dialog td {
          opacity: 1;
          color: var(--paper-grey-900);
          font-weight: 800;
          max-width: 250px;
        }
        mwc-dialog.unit-picker-dialog td.unit {
          /* min-width: 300px;
          display: inline-block;
          */
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
        }
        mwc-dialog.unit-picker-dialog td.employer, mwc-dialog.unit-picker-dialog th.employer {
          min-width: 150px;
        }
        span.match_name {
          white-space: break-spaces;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;

        }

        span.match_rating {
          font-weight: 100;
          font-size: 75%;
          background-color: var(--match-color);
          color: white;
          border-radius: 10px;
          padding: 2px 4px;
          margin: 2px 4px;
          display: inline-flex;
          align-items: center;
        }
        span.nomatch {
          text-transform: uppercase;
          background-color: var(--match-color);
          color: white;
          border-radius: 10px;
          padding: 2px 4px;
          margin: 2px 4px;
          display: inline-block;
        }
        span.has_conflict {
          font-size: 75%;
          text-transform: uppercase;
          background-color: var(--paper-yellow-600);
          color: white;
          border-radius: 10px;
          padding: 2px 4px;
          margin: 2px 4px;
          display: inline-block;
        }
        span.conflict_icon {
          font-family: "Material Icons";
          display: inline-block;
        }

        mwc-icon.conflict_warning {
          font-size: 14px;
          color: var(--paper-yellow-600);
        }

.breathing {
    animation: breathing 2s ease-out infinite normal;
    }


@keyframes breathing {
  0% {
    transform: scale(0.7);
  }

  25% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(0.7);
  }
}

`;




class ImportPage extends MDuesPage {
  static styles = [super.styles, import_export_page_styles]
  static icon = "cloud_upload"
  static default_title = "Import Data"

  get form_name() { return "Import" }

  constructor() {
    super();
    this.title = "Import";
    this.build_cache = debounce(this.build_cache_debounced.bind(this), 250);
    this.requestValidate = debounce(this.requestValidateDebounced.bind(this), 250);
    this.notification = debounce(this.notificationDebounced.bind(this), 250);
    this.files = [];
    this.data = null;
    this.selected = new Set();
    this.select_all = false;
    this.filter = [];
    //this.filter = { ignored: true, imported: true, good_match: false, poor_match: false };
    this._display_limit = null;
    this.render_count = 1;
    // TODO: import shouldn't need any change
    this.XLSXworker = Comlink.wrap(new Worker(new URL('/src/components/import-worker.js', import.meta.url), { type: 'module' }));
    // TODO: validation 
    this.ValidateWorker = Comlink.wrap(new Worker(new URL('/src/components/validation-worker.js', import.meta.url), { type: 'module' }));
    this.import_count = 0;
    this.pending_searches = [];
    this.search_received = new Set();
    this._sort = [{ col: 'row_number', dir: 1 }];
    this.queue = [];
    this.pqueue = [];
    this.processing_queue = null;
  }

  handleRowClick(e, row) {
    if (!this.rowSelectable(row)) {
      e.stopPropagation();
    } else {
      this.toggleSelected(row.row_id, row);
      if (e.shiftKey && this.last_selected) {
        let last = this.data.filtered.findIndex(f => f.row_id === this.last_selected);
        let current = this.data.filtered.findIndex(f => f.row_id === row.row_id);
        if (last >= 0 && current >= 0) {
          let start = last > current ? current : last;
          let end = last > current ? last : current;
          this.data.filtered.slice(start,end).filter(f => f.row_id !== this.last_selected).forEach(f => this.toggleSelected(f.row_id, f));
        }
      }
      this.last_selected = row.row_id;
    }
  }


  renderCell(celltype, value, errors, title, col){
    let text;
    switch (celltype) {
      case "text":
        return html`
                            <td class="textcell" column=${col} ?errors=${errors} title=${title || value || '[none]'}> 
                              <div><span class="overflow_ellipsis">${value}</span></div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      case "local":
        return html`
                            <td class="numbercell" column=${col} ?errors=${errors} title=${title || value || '[none]'}> 
                              <div>${value}</div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      case "contact":
        let {name, email, phone} = value;
        return html`
                            <td class="contactcell" column=${col} ?errors=${errors} title=${`${name}\n${email}\n${phone}`} >
                              <div class="contactname">${name}</div>
                              <div class="contactemail">${email?.split('@').map((e,i) => html`${e}${i ===0? '@':''}&#8203;`)}</div>
                              <div class="contactphone">${phone}</div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      case "date":
        let d = value && dayjs(value);
        text = d?.format('MM-DD-YY') || '';
        title = d?.format('MM-DD-YYYY') || value || '[none]';
        return html`
                            <td class="datecell" column=${col} ?errors=${errors} title=${title}>
                              <div>${text}</div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      case "number":
        value = value?.toLocaleString?.();
        text = value || '';
        title = value || '[none]';
        return html`
                            <td class="numbercell" column=${col} ?errors=${errors} title=${title} > 
                              <div>${text}</div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      case "pct":
        value = typeof(value) === 'string' ? value : value !== null ?  (value/100)?.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:3}) : undefined;
        text = value || '';
        title = value || '[none]';
        return html`
                            <td class="numbercell" column=${col} ?errors=${errors} title=${title} >
                              <div>${text}</div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      case "amt":
        value = value !== null ? value?.toLocaleString(undefined, { style: 'currency', currency: 'USD' }) : undefined;
        text = value || '';
        title = value || '[none]';
        return html`
                            <td class="amtcell" column=${col} ?errors=${errors} title=${title || value || '[none]'} > 
                              <div>${text}</div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      case "bool":
        return html`
                            <td class="boolcell" column=${col} ?errors=${errors} title=${title || value || '[none]'} >
                              <div>${value ? html`<mwc-icon>check</mwc-icon>` : ''}</div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      default:
        return html`<td>cannot format ${header.type}</td>`
        break;
    }
  }
l

  renderRow(row, i) {
    let selectable = this.rowSelectable(row);
    let selected = this.selected.has(row.row_id);
    let deactivate = row.deactivate;
    let loaded = row.data_loaded;
    let validated = row.validated;
    let imported = row.status === 'imported' 
      || (row.matching_increases && row.matching_increases.length > 0)
       || row.deactivation_complete;
    if (row.row_hash == "eb37c09e0e858ce608c0b8c6f0e419200bf85fa0c8ba712bf2496b2b86e4ce17") {
      console.warn("CHECKING", imported, row.status, row.matching_increases, row.matching_increases.length);
    }
    let has_errors = this.rowHasErrors(row);
    let in_progress = row.in_progress;

        // <!-- <td  title=${match_info_text(row)} class="textcell match" match=${row?.in_progress ? 'sending' : row?.new_unit ? 'new' : row.match_score > this.threshold_good ? "good" : row.match_score > this.threshold_poor ? "poor" : "bad"}> -->
        // <!--   ${row.match_pending ? html`` : html` -->
        // <!--       <div class="match_info" @click=${e => {e.stopPropagation(); console.log("MATCH CLICK", row); this.selector_dialog = row;}}> -->
        // <!--         ${this.matchInfo(row, i)} -->
        // <!--       </div> -->
        // <!--   `} -->
        // <!-- </td> -->
    return html`                            
      <tr 
      id=${row.row_id}
      rowindex=${i}
      sheetid=${row.sheet_id}
      ?imported=${imported}
      ?validated=${validated}
      ?data_loaded=${loaded}
      ?selected=${selected}
      @click=${e => { console.log("rowclick", e); this.handleRowClick(e, row) }}
      ?errors=${has_errors}
      ?onscreen=${row.onscreen}
      ?inprogress=${row.in_progress}
    >
        <td>
        <div>${row.row_number}</div>
        ${row.upload_errors ? html`<div class="upload_error_count">upload failed: ${row.upload_errors.length}</div>` : ''}
        </td>
        <td class="check_status">
          ${
          !validated ? html`<mwc-icon class="breathing">pending</mwc-icon>` :
          selectable ?
            html`<mwc-checkbox ?disabled=${!selectable || this.mdp?.locked} @click=${e => { e.stopPropagation(); this.handleRowClick(e, row); }} .checked=${selected}> </mwc-checkbox>`
            : html`<div class="non_checkable" ?imported=${imported} ?errors=${has_errors} ?info=${deactivate && true}>${
                deactivate ? html`<mwc-icon>hide_source</mwc-icon><span class="deactivated">deactivated</span>`
                : imported ? html`<mwc-icon>check</mwc-icon><span class="imported">${deactivate ? 'deactivated' : 'imported'}</span>`
                : has_errors ? html`<mwc-icon>error</mwc-icon><span class="errors">invalid</span>`
                : in_progress ? html`<mwc-icon>pending</mwc-icon><span class="errors">importing</span>`
            : nothing}</div>` }
        </td>
        ${ this.header.filter(h => !h.ignore).map(header => {
              let value = row[header.short];
              let errors = row.errors[header.short];
              errors = errors && errors.length > 0 ? errors : null;
              //let match = cell_match(row, header);
              //let title = cell_match_title_info(row, header);
              let title = "TITLE TBD";
              return this.renderCell(header.type, value, errors, title, header.short);
              //this.renderSimpleCell(header.type, row[header.short])
          })}
        </tr>
  `
  }

upload_file
  paginated = false;
  renderTable(top_item, last_item, bottom_item) {
    //console.log("render table", this?.data && this?.data?.filtered, this.data?.filtered?.length);
    const sort_icon = (fieldname) => html`<mwc-icon ?sort=${this.sort.find(s => s.col === fieldname)} ?reverse=${this.sort.find(s => s.col === fieldname && s.dir == 1)}>sort</mwc-icon>`;
    const filter_icon = (fieldname) => html`<mwc-icon ?filtered=${this.is_field_filtered(fieldname)}>filter_alt</mwc-icon>`;
    const filter_menu = (fieldname) => {
      //console.warn("FILTER MENU CHECK", this.filter_menu === fieldname, fieldname, this.filter_menu);
      return this.filter_menu === fieldname ? this.renderFilterMenu(fieldname) : nothing;
    }
    return html`
                          <table>
                            <thead>
                              <tr id="file_header" onscreen>
                                <th class="control" @click=${e => this.toggle_sort('row_number')}>
                                    ${sort_icon('row_number')}
                                    ${filter_icon('row_number')}
                                </th>
                                <th class="control" @click=${e => this.toggle_sort('selected')} @mouseenter=${e => this.set_filter_menu('selected')} @mouseleave=${e => this.set_filter_menu(null)} >
                                  <div style="position: relative; left: -6px;">
                                    <mwc-checkbox @click=${e => { e.stopPropagation(); this.toggleAll() }} .checked=${this.select_all}></mwc-checkbox>
                                    ${filter_icon('selected')}
                                  </div>
                                    ${this.renderFilterMenu('selected')}
                                </th>
                                ${this.header.filter(h => !h.ignore).map(h => html`
                                <th 
                                    type=${h.type}
                                    @click=${e => this.toggle_sort(h.short)} 
                                    @mouseenter=${e => this.set_filter_menu(h.short)}
                                    @mouseleave=${e => this.set_filter_menu(null)} 
                                    title=${h.long}
                                  >
                                  <div>
                                    <span>${h.long_func ? h.long_func(this.data) : h.disp || h.short.replace(/_/g, ' ')}</span>
                                    ${sort_icon(h.short)}
                                    ${filter_icon(h.short)}
                                  </div>
                                  ${this.renderFilterMenu(h.short)}
                                </th>
                                `)}
                              </tr>
                            </thead>
                            <tbody id="file_data">
                            ${ repeat((this.data && this.data.filtered ? this.data.filtered : []), row => row.row_number, (row, i) => 
                              guard([this.import_count, row.epoch],() => this.renderRow(row, i))
                            )}
                            </tbody>
                          </table>
            `;
  }


  filter_enabled_cols = new Set()
  filter = [ ]
  include_filter = []

  toggle_enable_filter(col) {
    console.log("TOGGLE ENABLE", col);
    if (this.filter_enabled_cols.has(col)) {
      this.filter_enabled_cols.delete(col);
      this.filter = this.filter.filter(f => f.col !== col);
    } else {
      this.filter_enabled_cols.add(col)
      this.filter = [...this.filter, ...this.available_filters[col].map(f => ({col, filter: f}))]
    }
    console.log("TOGGLE ENABLE", [...this.filter])
    this.requestUpdate('filter')
    this.build_cache();
  }

  is_row_filtered(row) {
    //console.log("ALWAYS", this.filter.filter(({filter}) => filter.always));
    return this.include_filter.every(({filter}) => filter?.func?.(row)) && this?.filter?.every?.(({filter}) => !filter.func(row))// || this.filter.filter(({filter}) => filter.always).some(({filter}) => !filter.func(row));
  }

  is_field_filtered(field) {
    return this.filter.find(f => f.col === field) || this.include_filter.find(f => f.col === field);
  }
  is_filter_enabled(field, filter) {
    return this.filter.find(f => f.col === field && f.filter.name === filter.name);
  }
  is_include_filter_enabled(field, filter) {
    return this.include_filter.find(f => f.col === field && f.filter.name === filter.name);
  }

  toggleFilter(field, filter) {
    if (this.is_filter_enabled(field, filter)) {
      this.filter = this.filter.filter(f => !(f.col === field && f.filter.name === filter.name));
      if (!this.filter.some(f => f.col === field)) {
        this.filter_enabled_cols.delete(field);
      }
    } else {
      this.filter = [...this.filter, { col: field, filter}];
      this.filter_enabled_cols.add(field);
    }
    console.log(this.filter);
    this.build_cache();
  }

  toggleIncludeFilter(field, filter){
    console.log("TOGGLING", field, filter.name);
    if (this.include_filter.find(f => f.col === field && f.filter.name === filter.name)) {
      this.include_filter = this.include_filter.filter(f => !(f.col === field && f.filter.name === filter.name));
    } else {
      this.include_filter = [...this.include_filter, { col: field, filter}];
    }
    console.log("INCLUDE NOW", [...this.include_filter]);
    this.requestUpdate('include_filter')
    this.build_cache();
  }

  set_filter_menu(field_name){
    //this.renderRoot.querySelectorAll('mwc-menu.filter_menu').forEach(m => { if (m.id !== `filter_menu_${field_name}`) m.open = false });
    if (this.filter_menu !== field_name) {
      let old = this.filter_menu;
      this.filter_menu = field_name;
      this.requestUpdate("filter_menu", old);
    }
    //await this.updateComplete;
    //this.renderRoot.querySelectorAll('mwc-menu.filter_menu').forEach(m => { if (m.id !== `filter_menu_${field_name}`) m.open = false });
    //this.renderRoot.getElementById(`filter_menu_${field_name}`).open = true;
  }

  /*
  match_threshold = 0.7;
  threshold_good = 0.85;
  threshold_poor = 0.5;
  */
  available_exclude_filters = {
    selected: [
      {name: 'checked', func: row => this.selected.has(row.row_id)},
      {name: 'uploaded', func: row => row.status === 'imported' || (row.matching_increases && row.matching_increases.length > 0) },
      {name: 'unchecked', func: row => this.rowSelectable(row) },
      {name: 'invalid', func: row => this.rowHasErrors(row) }
    ],

  }
  available_include_filters = {
    comments: [
      {name: "has comment", func: r => r.comments && r.comments !== '' },
    ],
    negotiations: [
      {name: "in negotiation", func: r => r.negotiations },
    ]
  }

  renderFilterMenu(field_name) {
    // toggle_sort(field_name)
    let excludes = this.available_exclude_filters[field_name];
    let includes = this.available_include_filters[field_name];

    return this.filter_menu === field_name && (excludes || includes) ? html`
    <div class="filter_menu_container">
      <mwc-menu class="filter_menu" id=${`filter_menu_${field_name}`} ?open=${this.filter_menu === field_name} @closed=${e => this.set_filter_menu(null)}>
        ${excludes ? html`
        <mwc-list-item>
          <div class="filter_item filter_all">
            <mwc-checkbox @click=${e => { e.stopPropagation(); this.toggle_enable_filter(field_name) }} .checked=${!this.filter_enabled_cols.has(field_name)}></mwc-checkbox>
            all rows
          </div>
        </mwc-list-item>
        <li divider role="separator"></li>

        ${excludes.map(filter => html`
        <mwc-list-item>
          <div class="filter_item">
            <mwc-checkbox
            @click=${e => { e.stopPropagation(); this.toggleFilter(field_name, filter) }}
            .checked=${!this.filter_enabled_cols.has(field_name) || !this.is_filter_enabled(field_name, filter)}
            ></mwc-checkbox>
            ${filter.name}
          </div>
        </mwc-list-item>
        `)}
        ` : nothing}
        ${excludes && includes ? html`<li divider role="separator"></li>` : nothing}
        ${includes ? html`
        ${includes.map(filter => html`
        <mwc-list-item>
          <div class="filter_item">
            <mwc-checkbox
            @click=${e => { e.stopPropagation(); this.toggleIncludeFilter(field_name, filter) }}
            .checked=${this.is_include_filter_enabled(field_name, filter)}
            ></mwc-checkbox>
            ${filter.name}
          </div>
        </mwc-list-item>
        `)}
        ` : nothing}
      </mwc-menu>
    </div>` : nothing;

  }
  renderExtraItems() {
    return html`
                  <div class="buttons" slot="actionItems">
                      <mwc-button ?disabled=${this.mdues_period?.locked} slot="actionItems" id="upload_button" icon="file_upload" extended label=${this.data ? 'new file' : 'upload file'} @click=${e => { this.renderRoot.getElementById('file_chooser').click(); this.renderRoot.getElementById('upload_button').blur(); }}></mwc-button>
                      ${this.data ? html`<mwc-button raised ?disabled=${this.selected.size === 0} @click=${e => this.importSelected()}>${this.data ? `import${this.selected.size > 0 ? ` ${this.selected.size} row${this.selected.size > 1 ? 's' : ''}` : ''}` : nothing}</mwc-button>` : nothing}
                      ${this.import_rows_in_progress ? html`<span>${this.import_rows_in_progress} data points to upload&hellip;</span>` : ''}
                  </div>
    ${super.renderExtraItems()}
    `
  }

  renderControls() {
    
    return html`
                <div class="table_controls" ?selection=${this.selected.size > 0}>

                  ${this.paginated ? this.renderNav() : ''}



                  <h2>${this.data ? html`${this.data.source.name}` : nothing}</h2>
                </div>
                `
/*
                  (loaded ${this.data?.filtered?.filter(d => d.validated)?.length}/${this.data.data.length} rows${this.data.total_error_rows > 0 ? html`&mdash;<span class='error_count'>${this.data.total_error_rows} with errors</span>` : ''})` : 'unknown'}
    return html`
                <div class="table_controls" ?selection=${this.selected.size > 0}>
                ${this.selected.size > 0 ? html`<div class="selection_info">
                ${this.selected.size} item${this.selected.size === 1 ? '' : 's'} selected</div>` 
                : html`<h2>${this.data ? this.data.source.name : 'unknown'}</h2>`}
                  ${this.selected.size > 0 ? html` <div class="buttons">
                    <mwc-button raised @click=${e => this.importselected()}>import selected</mwc-button>
                </div>` : html``}
                </div>
                `
                */
  }

  renderNav() {
    let top_item = this.display_item + 1;
    let last_item = this.data && this.data.filtered ? this.data.filtered_length : 'unknown';
    let bottom_item = Math.min(this.display_item + this.display_limit, last_item);
    return html`
                <div id="navigation">
                  <div id="navigation-info">${top_item}-${bottom_item} of ${last_item}</div>
                  <mwc-icon-button ?disabled=${this.display_item <= 0} icon="chevron_left" @click=${e => this.display_item = this.display_item - this.display_limit}></mwc-icon-button>
                  <mwc-icon-button ?disabled=${this.display_item >= (this.data.filtered_length - (this.data.filtered_length % this.display_limit))} icon="chevron_right" @click=${e => this.display_item = this.display_item + this.display_limit}></mwc-icon-button>
                </div>`;
  }
  renderPage() {
    this.render_count += 1;

    let top_item = this.display_item + 1;
    let last_item = this.data && this.data.filtered ? this.data.filtered_length : 'unknown';
    let bottom_item = Math.min(this.display_item + this.display_limit, last_item);

    //${this.renderControls()}
    //
    return html`
            <div class="column" ?dragging=${this.dragging} ?data=${this.data && !this.dragging} id="drop-target" @drop=${e => { e.preventDefault(); this.dragDrop(e) }} @dragenter=${e => this.dragEnter(e)} @dragover=${e => this.dragEnter(e)} @dragleave=${e => this.dragLeave(e)} >
            ${true && this.data && this.data.type === 'mdues' ? html`
                  ${this.data && this.data.data ? html`
                    <div class="table-container" id="tablecontainer">
                        ${!this?.progress?.complete ? this.renderProgress(this.progress) : nothing}
                        <div class="table-scroller" >
                        ${this?.progress?.complete ? this.renderTable(top_item, last_item, bottom_item) : nothing}
                           ${ 
                             nothing
                             //(this.data && this.data.filtered && this.data.filtered.some(d => !d.validated)) ? 
                            //html`<progress-circle class="progress_centered" .status=${'animate'} .icon_incomplete=${""} .icon_complete=${""}></progress-circle>`
                            //this.renderProgress(this.progress)
                            }
                        </div>
                      </div>
                  ` : html`` /*FULLTIME
                        <ul> ${this?.data?.filtered?.map(d => html`<li>${d.employer}/${d?.unit?.name}</li>`)} </ul>
                               <td class="remove"><mwc-icon-button @click=${e => this.deleteContrib(c)} icon="delete"></mwc-icon-button></td>
                  */} 

            ` : html`

              <div class="drag_placeholder">
            ${(this.data && (this.data.type === 'loading' || (this.queue && this.queue.length > 0))) ? html`
              <progress-circle style="--progress-color: white; --progress-bg: var(--paper-grey-700); --progress-size: 128;" .status=${'animate'} .icon_incomplete=${""} .icon_complete=${""}></progress-circle>
              ` : html`
            ${ this.data && (this.data.type === 'unknown' || this.data.type === 'error') ? html`
            <div class="load_message">${this.data.message}</div>
            ` : ``}
            ${ this.mdues_period?.locked ? html`<div>PERIOD IS LOCKED <mwc-icon>lock</mwc-icon></div>` : html`<div>DROP FILES TO UPLOAD</div>`}
              
              `}
            </div>
            `} 
        </div>
        ${this.selector_dialog ? this.renderMatchDialog() : nothing}
    <input
        id="file_chooser"
        type="file"
        multiple="false"
        accept=".xlsx"
        @change=${e => { console.log("file chooser change", e); for (const f of e.target.files) { console.log("adding a file"); this.addFile(f); this.renderRoot.getElementById('file_chooser').value = null; } }}
        hidden>
        `;
  }
            //<mwc-fab class="pink" id="upload_button" icon="file_upload" extended label="Open File" @click=${e => { this.renderRoot.getElementById('file_chooser').click(); this.renderRoot.getElementById('upload_button').blur(); }}></mwc-fab>

  get progress() {
    return this._progress;
  }

  set progress(p) {
    if (this._progress !== p) {
      const old = this._progress;
      this._progress = {...p, complete: old?.complete};
      this.requestUpdate('progress');
      if (p?.complete !== old?.complete) {
        (async () => {
          //this._progress.awaiting_complete = true;
          await this.updateComplete;
          await wait(500);
          this._progress.complete = p.complete;
          //this._progress.awaiting_complete = false;
          this.requestUpdate('progress');
        })();
      }
    }
  }

  renderProgress(progress) {
    const {bytes_read, processed, validated, matched, awaiting_complete} = progress;
    const blocks = [
      {title: "reading file", unit: 'bytes', data: bytes_read},
      {title: "processing data", unit: 'rows', data: processed},
      {title: "validating", unit: 'rows', data: validated},
      {title: "matching", unit: 'rows', data: matched}
    ]
    return html`
      <div class="progress_scrim"></div>
      <div class="progress_overlay">
      ${blocks.map(({title, unit, data: {finished, total, partial} = {finished:0, total:0, partial: 0}}) => html`
        <div class="progress_block">
          <span class="progress_title">${title}</span>
          <span class="progress_numbers">${finished}/${total} ${unit}</span>
          <mwc-linear-progress progress=${finished/total} buffer=${1 - (partial/total)}></mwc-linear-progress>
        </div>
      `)}
      ${awaiting_complete ? html`<div><mwc-icon>check</mwc-icon></div>` : nothing}
      </div>`
  }

  async fetchMatches() {
    let merged_search = new Map();
    let visible = this.data?.data?.filter?.((r) => this.visible(r));
    this.data = {...this.data, total_rows: visible.length, validated_rows: new Set(), sent_rows: new Set(), received_rows: new Set()};
    visible.forEach(async r => {
      r.match_score = 0;
      r.match_pending = true;
      r.epoch += 1;

      //console.log("COMPUTE KEY FOR", r);
      let key = `${r.state}::${r.council}::${r.local}::${r.master}`;
      //console.log(key);
      if (!merged_search.has(key)) {
        merged_search.set(key, {
          params: { state: r.state, council: r.council, local: r.local, master: r.master },
          rows: [r]
        });
      } else {
        let e = merged_search.get(key);
        merged_search.set(key, { ...e, rows: [...e.rows, r]});
      }
    });
    //console.log(`Merged ${visible.length} -> ${merged_search.size}`);
    merged_search.forEach((v, k) => this.queueWork(`do search (${v.rows.length} rows)`, null, () => this.doSearch(k, v.params, v.rows)));
  }

  
  /* 
   * //TODO: watch for period changes and re-fetch data: 
    if (this.data) {
      this.fetchMatches();
    } */

  async doSearch(key, params, rows) {
    this.data.sent_rows = this?.data?.sent_rows || new Set();
    rows.forEach(row => {
      row.errors = { needs_validation: ["needs_validation"] };
      if (this?.data?.sent_rows?.has?.(row.sheet_id)) console.warn("row already sent", row.row_number, row.pending_requests + 1);
      this.data.sent_rows.add(row.sheet_id)
      row.pending_requests = row.pending_requests ? row.pending_requests + 1 : 1;
    });
    this.progress = {
      ...this.progress,
      complete: this.data?.total_rows && this.data?.total_rows <= this.data?.received_rows?.size,
      matched: {
        partial: this.data?.sent_rows?.size,
        finished: this.data?.received_rows?.size,
        total: this?.data?.total_rows
      }
    }
    params.resends = 0;
    let { state, council, local, master } = params;

    if (master) {
      await this.doMasterSearch(key, params, rows, state, council, local, master);
    } else {
      await this.doLocalSearch(key, params, rows, state, council, local);
    }
  }

  async doMasterSearch(key, params, rows, state, council, local, master) {
    console.log("master search", key);
    // TODO: replace agreement_entry with stat_override -- no other columns seem to be necessary
    const agreement_query = gql`
      query master_agreement_search($state: String, $year: Int){
        results:md_agreement(where: {_and: {year: {_eq: $year}, is_master: {_eq: true}, locals: {local: {state: {_eq: $state}}}}}) {
          id
          year
          is_master
          state
          council
          local
          master

          increases {
            ...IncreaseFields
          }
          locals {
            local {
              stat {
                year
                units
              }
              local
              council
              state
            }
          }
        } 
      }
      ${local_fields}
      ${increase_fields}
    `;

    client.query({
      fetchPolicy: 'network-only',
      query: agreement_query,
      variables: { state, year: this.period_year }
    })
      .then(data => {
        if (data.data && data.data.results) {
          const d = data.data.results;
          console.log("master data", d);
          d.forEach(r => {
            console.log("got m data", r)
            r.locals = r?.locals?.map?.(l => ({ 
              ...l.local,
              stat_units: l.local.stat?.find?.(s => s.year === this.period_year)?.units ?? 0, // TODO: load the correct year
            }));
            r.stat_units = r.locals?.reduce((acc, l) => acc + l.stat_units, 0);
            r.num_locals = r.locals?.length;
            delete r.locals;
          });
          rows.forEach(r => {
            r.match_type = 'master';
            this.queueWork(`new master data row #{r.row_number}: ${r.master}`, r, () => this.newMasterSearchData(key, r, d));
          });
        }
      })
      .catch(error => console.error(key, error));

    await wait(2500);
    if (!this.search_received.has(key)) {
      params.resends += 1;
      if (params.resends < 2) {
        this.doSearch(key, params, rows);
      } else {
        console.error("search timed out", params.resends, row_text);
        //this.validate_rows(rows);
        rows.forEach(r => {
          r.errors = { ...r.errors, master: ["search timed out"] };
          return this.validate(r);
        });
      }
    }
  }

  // DEFUNCT?
  async doLocalSearch(key, params, rows, state, council, local) {
    /// TODO: convert to locals
    const local_query = gql`
      query local_search($state: String, $council: Int, $local: Int, $year: Int){
        results:md_local(
          where: {
            _and: 
            [
              {state: {_ilike: $state}},
              {council: {_eq: $council}},
              {local: {_eq: $local}},
            ]
          }
          ){
            id
            ...LocalFields

            stat {
            units
            year
            localid
            aff_pk
          }
            agreements(where: {agreement: {year: {_eq: $year}}}) {
              agreement {
                id
                year
                is_master
                master
                increases {
                  ...IncreaseFields
                }
              }
            }
 
          }
        }
      ${local_fields}
      ${increase_fields}
    `;

      //console.log("QUERYING LOCALS", {variables: { state, council, local, year: this.period_year }})

    client.query({
      fetchPolicy: 'network-only',
      query: local_query,
      variables: { state, council, local, year: this.period_year }
    })
      .then(data => {
        //console.log("local search", key, params, rows )
       // console.log("local data", data);
        if (data.data && data.data.results) {
          const d = data.data.results.map(r => ({
            ...r,
            //TODO: prefer to match deprecated units to deprecated units
            //match_unit: r.unit !== undefined && r.unit !== null && String(r.unit.trim()) !== '' ? r.unit.trim().toLowerCase() : null,
            current_agreement: r.agreements.filter(t => t?.agreement?.year === this.period_year)?.[0]?.agreement
          }));
          rows.forEach(r => {
            r.match_type = 'local';
            r.pending_requests = r.pending_requests ? r.pending_requests - 1 : 0;
            this.queueWork(`new unit data row #${r.row_number}: ${r.employer}`, r, () => this.newLocalSearchData(key, r, d));
          });
        }
      })
      .catch(error => console.error(key, error));

    //this.pending_searches.push({row: row, args: args});
    await wait(2500);
    if (!this.search_received.has(key)) {
      params.resends += 1;
      if (params.resends < 2) {
        this.doSearch(key, params, rows);
      } else {
        console.warn("search time out", params.resends, row_text);
        //this.validate_rows(rows);
        rows.forEach(r => {
          r.errors = { ...r.errors, local: ["search timed out"] };
          return this.validate(r);
        });
      }
    }
  }

  async newMasterSearchData(search_key, row, data) {
    console.log("newMasterSearchData", search_key, row, data);
    this.search_received.add(search_key);
    if (data.length === 0) {
      console.warn("NO DATA FOUND FOR", search_key, row, data);
      row.no_master_data = true;
    }
    row.data_received = row.data_received ? row.data_received + 1 : 1;

    let match_text = row.master?.toLowerCase?.().trim?.();
    let match_map = new Map();
    let top_matches = [];
    let best_match, worst_match;

    data.forEach(d => {
      d.match_text = d.match_text ?? d.master?.toLowerCase?.()?.trim?.();
      let score = similarity(String(match_text), String(d.match_text));

      let m = {
        match_score: score,
        match_fields: {
          master: { val: score, comparing: [match_text, d.match_text] }
        }
      };
      match_map.set(d.id, m);

      if (!best_match || match_map.get(best_match.id).match_score < m.match_score) {
        best_match = d;
        top_matches.unshift(d);
        top_matches = top_matches.slice(0,5);// top_matches.sort((a,b) => match_map.get(b.id).match_score - match_map.get(a.id).match_score);
      } else if (!worst_match || match_map.get(worst_match.id).match_score < m.match_score) {
        top_matches.push(d);
        top_matches = top_matches.sort((a,b) => match_map.get(b.id).match_score - match_map.get(a.id).match_score).slice(0,5);
        worst_match = top_matches[top_matches.length - 1];
      } 
    });

    

    // row.match_options = [best_match, ...top_matches.filter(m => m.id !== best_match.id)];
    // row.all_match_options = data;//.sort((a,b) => match_map.get(b.id).match_score - match_map.get(a.id).match_score);
    // row.match_map = match_map;
    delete row.match_pending;


    if (best_match) {
      let m = match_map.get(best_match.id);
      row.match_score = m.match_score;
      row.match_fields = m.match_fields;
      row.master_data = row.match_score > 0.9 ? best_match : null;
      if (!row.master_data) {
        row.no_master_data = true;
      }

      row.data_loaded = row.master_data ?? {};
      this.set_agreement(row, best_match);
      row.row_id = `${row.sheet_id}::${best_match.id}`;
      this.matched_rows = [...this.matched_rows, row]
    } else {
      row.match_score = 0;
      row.match_fields = null;
      row.row_id = `${row.sheet_id}::null`;
      this.unmatched_rows = [...this.unmatched_rows, row]
    }
    row.epoch += 1;
    this.data.received_rows.add(row.sheet_id);
    this.build_cache();
  }

  async newLocalSearchData(search_key, row, data) {
    //console.warn("newLocalSearchData", search_key, row, data);
    this.search_received.add(search_key);
    //console.log("NEW DATA", row.employer, row.sheet_id, "(total:)", this.search_received.size);
  
    if (data.length === 0) {
      console.warn("NO DATA FOUND FOR", search_key, row, data);
      row.no_local_council_data = true;
    }

    if (data.length > 1) {
      console.error("TOO MANY LOCALS", data);
      row.too_many_locals = true;
    }

    row.data_received = row.data_received ? row.data_received + 1 : 1;

    row.local_data = data?.[0] ?? {};
    const original_stat = row.local_data?.stat;
    //console.log("STAT", row.employer, row.local_data?.stat);
    //console.log("...ADDL:", row.local_data, row.local_data.stat, row.local_data.stat?.find?.(s => s.year === this.period_year));
    row.local_data.stat_units = row?.local_data?.stat?.find?.(s => s.year === this.period_year)?.units; //FIXME load stat data
    row.stat_units = row.local_data.stat_units;
    if (!row.local_data?.stat_units) {
      console.error("NO STAT", row.employer, row.local_data?.stat_units);
      console.log("FULL DATA", data);
    }


    row.row_id = `${row.sheet_id}::${row.local_data.id}`;

    row.in_master = row.local_data?.current_agreement?.is_master ?? false;
2693
    delete row.match_pending;
    row.data_loaded = row.local_data ?? {};

    this.set_agreement(row, row.local_data?.current_agreement);

    row.epoch = row.epoch + 1;
    this.data.received_rows.add(row.sheet_id);
    this.build_cache;
  }

  matched_rows = []
  unmatched_rows = []

  set_agreement(row, best_match) {
    //title = ${ row.match_fields && row.match_fields[header.short] ? `"${row.match_fields[header.short].comparing[1]}": ${Math.round(row.match_fields[header.short].val * 100)}%` : `${header.short}: no match` }
    // console.log("SET AGREEMENT", row.employer);
    // console.warn("ROW:", JSON.stringify(row, null, 2));
    // console.warn("AGREEMENT:", JSON.stringify(best_match, null, 2));


    //console.log("agreements:", best_match.all_agreements);
    // const agreements = row.match_type === 'master' ? [{agreement: best_match}] : best_match.all_agreements.filter(t => t?.agreement?.period_id === get_current_period()?.id);
    // if (agreements.length > 1){ //|| best_match.all_agreements.length > 1) {
    //   console.error("UNEXPECTED?: MORE THAN 1 MATCHING TARGET");
    //   console.log("agreements", agreements);
    //   console.log("all_agreements", best_match.all_agreements);
    // }
    row.matched = best_match;
    const agreement = best_match;
    if (!agreement) {
      row.increase_record = null;
      row.matching_increases = [];
      return;
    }
    if (agreement?.is_master) {
      if (row.match_type === 'unit') {
        row.match = false;
      }
      row.is_master_agreement = true;
      row.in_master = true;
    }

    const { members, annual, annual_base, cents_hourly, hourly_base, pct, agreement_effective, agreement_expires, effective, negotiations: in_negotiations=false, state, council, local, subunit, employer, master } = row;
    const [type, value, base ] = pct !== null && pct !== undefined ? ['pct', pct, null] : (cents_hourly ? ['hourly', cents_hourly, hourly_base] : (annual ? ['annual', annual, annual_base] : ['pct', null, null]));
    row.increase_record = { 
      year: this.period_year,
      agreement_id: agreement?.id,
      row_src: row.sheet_id,
      state,
      council,
      local,
      subunit,
      employer,
      master,
      members,
      type,
      value: value !== null && value!== undefined ? value : null,
      base: base !== null && base !== undefined ? base  : null,
      in_negot: in_negotiations ? true : false,
      eff_dt: effective, 
      agr_eff_dt: agreement_effective,
      exp_dt: agreement_expires, //FIXME: this is not a thing
      agr_exp_dt: agreement_expires,
      notes: row.comments,
      contact: row.contact,
      src_file_hash: this.data.hash,
      src_file_row: row.sheet_row,
      src_file_row_hash: row.row_hash,
    };
    if (this.match_debugging) {
      agreement.increases.forEach(i => {
        console.log("INCREASE", i, row);
        //console.log(`members, i.members, members === i.members, row neg: ${in_negotiations}, inc neg: ${i.in_negotiations ? true : false}`, in_negotiations === (i.in_negotiations ? true : false), type === i.type, value === (i.value ? i.value : null), base === (i.base ? i.base : null))
        console.log(`members: ${members}===${i.members} (${members === (i.members !== null && i.members !== undefined ? i.members : null)}) && type: ${type} === ${i.increase_type} (${type === (i.increase_type !== null && i.increase_type !== undefined ? i.increase_type : null)}) && value: ${value} === ${(i.increase_value)} (${value === (i.increase_value !== null && i.increase_value !== undefined ? i.increase_value : null)}) && base: ${base} === ${(i.increase_base)} (${base === (i.increase_base !== null && i.increase_base !== undefined ? i.increase_base : null)})`);
        console.log("matches", members === (i.members !== null && i.members !== undefined ? i.members : null) && type === (i.increase_type !== null && i.increase_type !== undefined ? i.increase_type : null) && value === (i.increase_value !== null && i.increase_value !== undefined ? i.increase_value : null) && base === (i.increase_base !== null && i.increase_base !== undefined ? i.increase_base : null));
      });
    }
    //row.matching_increases = agreement.increases.filter(i => members === i.members && type === i.increase_type && value === (i.increase_value !== null && i.increase !== undefined ? i.increase : null) && base === (i.increase_base));
    row.matching_increases = agreement.increases.filter(i => 
      row.row_hash === i.src_file_row_hash_
        || (
          employer === i.employer 
            && master === i.master
            && members === (i.members ?? null) 
            && type === (i.type ?? null) 
            && (value ? Math.round(value*10000) : 0) === (i.value !== null && i.value !== undefined ? Math.round(i.value*10000) : 0) 
            && (base !== undefined && base !== null ? Math.round(base*10000) : null) === (i.base !== null && i.base !== undefined ? Math.round(i.base*10000) : null)
        )
      );
     
    //console.log("ROW MATCH", row.match, row.match_fields, row.unit);
    row.row_id = `${row.sheet_id}::${agreement.id}::${row.matching_increases?.[0]?.id ?? ''}`//JSON.stringify(row.increase_record);

    // console.warn("VALIDATING, DATA IS", JSON.stringify(row.data_loaded));
    this.validate(row);
  }

  queueWork(n, d, f) {
    if (d && d.onscreen) {
      this.pqueue.push({f: f, d: d, n: n});
    } else {
      this.queue.push({f: f, d: d, n: n});
    }
    if (!this.processing_queue) {
      this.processing_queue = window.requestIdleCallback(this.processQueue.bind(this))
    }

    this.progress = {
          ...this.progress,
          complete: this.data?.total_rows && this.data?.total_rows <= this.data?.received_rows?.size,
          matched: {
            partial: this.data?.sent_rows?.size,
            finished: this.data?.received_rows?.size,
            total: this?.data?.total_rows
          }
        }
  }
  async processQueue(deadline) {
    while (deadline.timeRemaining() > 5 && (this.pqueue.length > 0 || this.queue.length > 0)){
      while (this.pqueue.length > 0) {
        let { f, n } = this.pqueue.shift();
        //console.log(`${this.queue.length}: running work`, n);
        f();
      }
     if(this.queue.length > 0 && deadline.timeRemaining() > 5) {
        let { f, n } = this.queue.shift();
        //console.log(`${this.queue.length}: running work`, n);
        f();
      }
      
    }
    this.progress = {
          ...this.progress,
          complete: this.data?.total_rows && this.data?.total_rows <= this.data?.received_rows?.size,
          matched: {
            partial: this.data?.sent_rows?.size,
            finished: this.data?.received_rows?.size,
            total: this?.data?.total_rows
          }
        }
    this.build_cache();
    //await this.updateComplete;
    if (this.queue.length > 0) {
      //console.log("WORK QUEUE: ", this.queue.length);
      this.processing_queue = window.requestIdleCallback(this.processQueue.bind(this))
    } else {
      console.warn("WORK QUEUE EMPTY!");
      this.processing_queue = null;

      if (this?.data?.data?.some(d => d.data_loaded && !d.validated)) {
        let unvalid = this?.data?.data?.filter(d => !d.validated);
        console.warn("unvalidated: ", unvalid);
        unvalid.forEach(d => this.validate(d));
        //unvalid.forEach(d => this.queueWork('cleanup validation', d, () => this.validate(d)));
      }
    }

  }

  validating = new Set();

  validate_queue = [];
  validate(row) {
    if (!row.data_loaded) {
      console.warn("row not loaded yet", row);
      return
    }
    if (!this.validating.has(row.sheet_id)) {
      this.validating.add(row.sheet_id);
      this.validate_queue.push(row);
    }
    this.requestValidate();
  }

  requestValidateDebounced() {
    const rows = this.validate_queue;
    this.validate_queue = [];
    this.validate_rows(rows);
  }

  validate_rows(rows) {
    if (!rows || rows.length === 0) return;
    rows.forEach(r => {
      this.validating.add(r.sheet_id);
    });
    this.progress = {...this.progress, 
      //complete: this?.data?.total_rows && this?.data?.validated_rows.size == this?.data?.total_rows, 
      validated: {partial: this.validating.size, finished: this?.data?.validated_rows.size, total: this?.data?.total_rows}
    };
    new this.ValidateWorker(rows, get_current_period()?.year, 
      Comlink.proxy((r, error_delta, error_row_delta) => {
        //row.errors = {...r.errors};
        this.data.total_errors += error_delta;
        this.data.total_error_rows += error_row_delta;
        //this.queueValidatedRow({...r});
        //this.queueWork(`validation finish ${r.row_number}: ${r.employer}`, row, () => this.loadValidatedRow({...r}));
        //console.log("got data back from validator", r);
        //this.queueWork(`validation finish ${r.row_number}: ${r.employer}`, this.data.data.find(d => d.sheet_id === r.sheet_id), () => this.loadValidatedRow({...r}));
        this.loadValidatedRow(r);
    }));
  }
  loadValidatedRow(u) {
    let d = this.data.data.find(d => d.sheet_id === u.sheet_id);
    if (!u.is_validated) {
      console.error("row not validated", u);
      throw new Error("row not validated", u);
    }
    if (d) {
      d.errors = u.errors;
      d.is_validated = u.is_validated;
      d.validated = true;
      d.epoch = d.epoch + 1;
      if (this.data.validated_rows.has(u.sheet_id)) {
        console.warn("already validated", u);
      }
      this.data.validated_rows.add(u.sheet_id);
      //console.log("validated a row", {requested: this?.validating.size, finished: this?.data?.validated_rows.size, total: this?.data?.total_rows});
      this.progress = {...this.progress, 
        //complete: this?.data?.total_rows && this?.data?.validated_rows.size == this?.data?.total_rows, 
        validated: {partial: this.validating.size, finished: this?.data?.validated_rows.size, total: this?.data?.total_rows}
      };
    }
  }

  get filter() {
    return this._filter;
  }
  set filter(filter) {
    this._filter = filter;
    this.build_cache();
  }

  items_per_page = 50;
  get display_limit() {
    return this.paginated ? this.items_per_page : (this.data && this.data.filtered ? this.data.filtered.length : 0);
    ///return this.paginated ? this._display_limit : (this.data && this.data.filtered ? this.data.filtered.length : 0);
  }

  set display_limit(limit) {
    if (limit != this._display_limit) {
      this._display_limit = limit;
      this.build_cache();
    }
  }

  get display_item() {
    return this._display_item;
  }

  set display_item(item) {
    if (this.paginated) {
      let max_item = this.data.filtered_length - (this.data.filtered_length % this.display_limit);
      let change_item = item < 0 ? 0 : (item > max_item ? max_item : item);
      if (change_item != this._display_item) {
        this._display_item = change_item;
        this.build_cache();
      }
    }
  }


  get sort() {
    return this._sort;
  }

  set sort(sort) {
    this._sort = sort;
    if (this.data) this.data.is_sorted = false;
    this.build_cache();
  }

  toggle_sort(column) {
    let previous = this.sort.find(s => s.col === column);
    let prev_dir = previous ? previous.dir : 0;
    let next_dir = ((prev_dir + 2) % 3) - 1;
    this.sort = [{ col: column, dir: next_dir }, ...this.sort.filter(s => s.col !== column)].filter(s => s.dir !== 0);
  }
  async build_cache_debounced() {
    //console.log("rebuld cache", this?.data?.is_sorted);

    this.progress = {
          ...this.progress,
          complete: this.data?.total_rows && this.data?.total_rows <= this.data?.received_rows?.size,
          matched: {
            partial: this.data?.sent_rows?.size,
            finished: this.data?.received_rows?.size,
            total: this?.data?.total_rows
          }
        }
    if (this.data) {
      if (false && this.data && this.data.data) {
        let first_vis = this.data.data.findIndex(d => d.onscreen);
        let last_vis = this.data.data.reduce((acc, cur, i) => cur.onscreen ? i : acc, -1);
        //console.log("cleanup", this.data.data, first_vis, last_vis)
        if (first_vis > -1 && last_vis > -1) {
          this.data.data.slice(first_vis, last_vis).forEach(d => {
            if (!d.onscreen){
              d.onscreen = true;
              d.epoch += 1;
            }
          })
        }
        
      }
      if (!this?.data?.is_sorted) {
        this?.data?.data?.sort((a, b) => {
          //console.log("SORTING", this.sort);
          for (let sort of this.sort) {
            //console.log("SORT:", sort);
            let res;
            if (sort.col === 'selected') {
              res = sort.dir > 0 ? sorts[sort.col](this.selected.has(b.row_id), this.selected.has(a.row_id)) : sorts[sort.col](this.selected.has(a.row_id), this.selected.has(b.row_id));
            } else {
              res = sort.dir > 0 ? sorts[sort.col](a[sort.col], b[sort.col]) : sorts[sort.col](b[sort.col], a[sort.col]);
            }
            if (res !== 0) { return res };
          }
          return 0;
        });
        this.data.is_sorted = true;
      }
      this.data.filtered = this?.data?.data?.filter?.((r) => this.visible(r) && this.is_row_filtered(r) /*&& r.rendered */);
      //console.warn("filter", this.filter);
      //console.warn("Filtered with filters", [...this.filter], 'include filters', [...this.include_filter], 'result:', [...this.data.filtered]);
      //this.data.filtered = this.data.filtered?.filter?.(r => this.is_row_filtered(r));

      this.data.filtered_length = this?.data?.filtered?.length;
      if (this.paginated) this.data.filtered = this.data.filtered.slice(this.display_item, this.display_item + this.display_limit);
    }
    //console.log("UPDATED DATA", JSON.stringify(this.data, null, 2));
    console.log(this.data);
    this.requestUpdate("data");
  }

  visible(row) {
    return !row.ignore && row.status !== 'ignore';
  }

  rowHasErrors(row) {
    let e = row?.errors;
    let r = !row.deactivate && e && Object.keys(e)?.some(k => e[k] && e[k].length > 0);
    //if (r) console.warn("row has errors", row, e);
    return r;
  }
  rowSelectable(row) {
    return row.validated 
    && !row.deactivate
    && row.is_validated
    && !(row.matching_increases?.length > 0)
    && !(row.status === 'imported')
    && !row.in_progress 
    && !(row.match_type === 'local' && row.in_master) 
    && (!this.rowHasErrors(row))
    && (row.local_data || row.master_data )
  }

  toggleSelected(id, row) {
    console.log("toggling", id, row, this.rowSelectable(row));
    if (!this.rowSelectable(row)) {
      return;
    }
    if (this.selected.has(id)) {
      this.selected.delete(id);
    } else {
      this.selected.add(id);
    }
    row.epoch = row.epoch + 1;
    this.requestUpdate('selected');
  }
  toggleAll() {
    this.select_all = !this.select_all;
    if (this.select_all) {
      this.selected = new Set([...this.selected.keys(), ...this.data.data.filter((r) => this.visible(r) && this.rowSelectable(r)).map((r) => r.row_id)]);
    } else {
      this.selected = new Set();
    }
    this?.data?.data?.forEach(r => {
      if (this.visible(r) && this.rowSelectable(r)) r.epoch = r.epoch + 1;
    })
    this.requestUpdate('selected');
  }


  pending_increases = [];
  pending_rows = new Set();

  importSelected() {
    this.import_complete = false;
    this.pending_rows = new Set();
    let selected = this.data.data.filter((r) => this.selected.has(r.row_id));
    this.updates_in_progress = this.selected;
    this.selected = new Set();
    console.log(`importing ${selected.length} rows`);


    // let new_units = [];
    // let existing_units = [];
    selected.forEach(d => {
      d.in_progress = true;
      d.epoch = d.epoch+1;
    });

    this.pending_increases = [...this.pending_increases, ...selected];

    this.pending_increases.forEach(i => this.pending_rows.add(`${i.sheet_id}::increase`));
    //this.pending_increases.forEach(i => this.pending_rows.add(`${i.sheet_id}::agreement`));
    //this.pending_increases.filter(i => i?.agreement_info_record?.in_negotiation && !i?.agreement_saved).forEach(i => this.pending_rows.add(`${i.sheet_id}::agreement`));

    this.requestUpdate('import_rows_in_progress');
    this.queueWork('import queued data', null, () => this.importData());
  }

  get pending_data() {
    return this.pending_increases.length > 0;
  }
  get import_rows_in_progress() {
    return this.pending_rows.size;
  }

  importData() {
    console.log("IMPORTING DATA, PENDING?", this.pending_data, this.pending_increases);
    console.log(this.data);
    // data.import_file
    //
    window.router.invalidate();
    this.pending_increases.forEach(row => {
      this.importRow(row);
    });
    this.build_cache();
  }

  importRow(row) {
    let increase = row.increase_record;
    console.log("IMPORTING ROW", row, increase);
    let uploader = new EditIncrease(
      p => {
        console.log("saved increase row", p);
      },  // data update function
      { changeMap: null },  //initial variables
      p => { // finalizing function
        console.log("finalized increase", p);
        this.increaseUpsertSuccess(row, p); //{row_src: row.sheet_id});
      },
      (e, msgs) => { // error handler
        this.error = { data: this.data_property, error: e, msgs: msgs };
        console.error(this.error);
        this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
      });
    uploader.save(increase, {});
  }

  notify_count = 0;

  queueImportNotification() {
    this.notify_count += 1;
    this.notification();
  }
  notificationDebounced() {
    console.log("NOTIFYING", this.notify_count);
    const count = this.notify_count;
    this.notify_count = 0;
    this.dispatchEvent(
      new CustomEvent('snackbar',
        { 
          bubbles: true,
          composed: true,
          detail: {
            text: `imported ${count} row${count > 1 ? 's' : ''} ${ this.pending_increases.length > 0 ? `(${this.pending_increases.length} remaining)` : ""}` 
          }
        })
    );
  }

  increaseUpsertSuccess(row, increase) {
    let source_row = this.data.data.find(i => i.sheet_id === increase.row_src);
    if (source_row) {
      console.log("UPSERTED", source_row);
      source_row.epoch += 1;
      delete source_row.in_progress;
      this.selected.delete(source_row.row_id);
      if (this.selected.size === 0) {
        this.select_all = false;
      }
      source_row.matching_increases = [increase];
      source_row.status = 'imported';
      this.pending_rows.delete(`${source_row.sheet_id}::increase`);
      this.pending_increases = this.pending_increases.filter(i => i.sheet_id !== source_row.sheet_id);
      this.queueImportNotification();
    } else {
      console.error("UNABLED TO FIND UPSERT SOURCE", row, increase);
      // TODO: surface this by pushing onto row list with error?
    }
    //this.checkFinished();
  }

  importData__OLD() {
    return
    const max_unit_chunk = 50;
    const max_increase_chunk = 50;
    let increase_rows = [];
    let deactivate_rows = [];
    let alias_rows = [];
    let new_unit_rows = [];
    let agreement_priority = [];

    console.log("IMPORTING DATA.... PENDING?", this.pending_data);
    console.log("PENDING INCREASES", this.pending_increases);

    if (!this.pending_data){
      console.log("no work");
      return;
    }


    if (this.pending_agreements.length > 0) {
      // if there are units to create, do only that on this run
      while (this.pending_agreements.length > 0 && new_unit_rows.length < max_unit_chunk) {
        new_unit_rows.push(this.pending_agreements.shift());
      }
      console.warn(`CREATING ${new_unit_rows.length} UNITS`);
    } else {
      // process the increases
      while (this.pending_increases.length > 0 && deactivate_rows.length + increase_rows.length + agreement_priority.length < max_increase_chunk) {
        let next = this.pending_increases.shift();
        if (next?.agreement_info_record?.in_negotiation && !next.agreement_saved) {
          agreement_priority.push(next);
        } else {
          increase_rows.push(next);
        }
      }
      console.warn(`SENDING ${agreement_priority.length}+${increase_rows.length} INCREASES`);
    }

    alias_rows = increase_rows.filter(d => d?.import_aliases?.length > 0);

    //console.log(`importing ${increase_rows?.length} increases into existing units (${this.pending_increases.length} pending)`);
    //console.log(`importing ${alias_rows?.length} aliases (${this.pending_aliases.length} pending)`);
    //console.log(`importing ${new_unit_rows?.length} new units (${this.pending_agreements.length} pending)`);
    //console.log("pending keys", this.pending_rows);

    let increases = increase_rows.map(d => d.increase_record).filter(i => i);
    console.log("priority, increase", agreement_priority, increase_rows);
    let agreements = [...agreement_priority, ...increase_rows].map(d => d.agreement_info_record).filter(a => a);
    let deduped_agreements = [];
    let aids = new Map();
    console.log("importing ", agreements);
    agreements.forEach(a => {
      if (!aids.has(a.agreement_id)){
        deduped_agreements.push(a);
        aids.set(a.agreement_id, [a]);
      } else {
        aids.set(a.agreement_id, [...aids.get(a.agreement_id), a]);
        console.warn("ignoring duplicated agreements", aids.get(a.agreement_id));
      }
    });
    agreements = deduped_agreements;
    
    let aliases = [];
    alias_rows.forEach(r => r.import_aliases?.forEach(a => aliases.push(a)));
    let new_units = new_unit_rows.map(r => ({
      name: r.employer,
      state: r.state, 
      council: r.council,
      local: r.local,
      subunit: r.subunit
    }));

    let other_deactivates = new Set();
    let deactivates = deactivate_rows.filter(r => {
      if (other_deactivates.has(r.unit.id)) {
        return false;
      }
      other_deactivates.add(r.unit.id);
      return true;
    }).map(r => ({
      id: r.unit.id,
      name: r.unit.name,
      deprecated: true,
      deprecated_on: new Date().toISOString()
    }));

    console.log("ARGS:", {deact: deactivates, inc: increases, agr: agreements, ali: aliases, uni: new_units});
    if (increases.length + agreements.length + aliases.length + new_units.length + deactivates.length == 0) {
      console.warn("nothing to import!");
      this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { kind: 'error', text: `nothing to upload` } })); // TODO: undo
      return;
    }
    
    // TODO Update schema
    const mut = gql`
        mutation upsert_increase(
            $inc: [core_increase_insert_input!]!, 
            $agr: [core_agreement_info_insert_input!]!, 
            $ali: [core_unit_alias_insert_input!]!, 
            $uni: [core_unit_insert_input!]!
            $deact: [core_unit_insert_input!]!
            ) {
          agreement:insert_md_agreement_entry (
            objects: $agr,
            on_conflict: {
              constraint: core_agreement_info_pkey,
              update_columns: [agreement_id, effective_date, expires_date, in_negotiation]
            }
          ) {
            returning {
              agreement_id
              effective_date
              expires_date
              in_negotiation
            }
          }
          increase:insert_md_increase (
            objects: $inc,
            on_conflict: {
              constraint: core_increase_pkey,
              update_columns: [agreement_id, effective_date, members, increase_type, increase_value, increase_base_value, comment, contact]
            }
          ) {
            returning {
              agreement_id
              id
              row_info
              ...IncreaseFields
            }
          }
          alias:insert_core_unit_alias (
            objects: $ali,
            on_conflict: {
              constraint: core_unit_alias_pkey,
              update_columns: []
            }
          ) {
            returning {
              id
              unit_id
              name
            }
          }
          unit:insert_core_unit (
            objects: $uni,
            on_conflict: {
              constraint: core_unit_pkey,
              update_columns: [name, state, council, local, subunit]
            }
          ) {
            returning {
              ...UnitFields
              all_agreements {
                agreement {
                  id
                  name
                  promoted
                  period_id
                  increases {
                    ...IncreaseFields
                  }
                }
              }
            }
          }
          deactivated:insert_core_unit (
            objects: $deact,
            on_conflict: {
              constraint: core_unit_pkey,
              update_columns: [deprecated, deprecated_on]
            }
          ) {
            returning {
              ...UnitFields
            }
          }
        }
        ${increase_fields}
        ${unit_fields}
    `;
    client.mutate({
      mutation: mut,
      variables: {inc: increases, agr: agreements, ali: aliases, uni: new_units, deact: deactivates},
      refetchQueries: refetch_searches
     }).then(data => {
      window.router.invalidate();
      console.log("mutation results:", data);
      let {increase, agreement, alias, unit } = data?.data;
      this.handleUploadedIncreases(increase?.returning, alias?.returning);
      this.handleUploadedAgreements(agreement?.returning, agreement_priority);
      this.handleUploadedAliases(alias?.returning);
      this.handleUploadedUnits(unit?.returning, new_unit_rows);
      this.build_cache();

      if (new_unit_rows.length === 0 && this.pending_data) {
        console.log("queing remaining work");
        this.queueWork('import remaining rows', null, () => this.importData());
      }
     }).catch(error => {
       this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { kind: 'error', text: `upload failed (${increases.length}/${agreements.length}/${aliases.length}/${new_units.length})` } })); // TODO: undo
       console.error("ERROR WHILE SAVING INCREASES");
       console.warn("inc:", increases); 
       console.warn("agr:", agreements);
       console.warn('alias:', aliases);
       console.warn('new:', new_units);
       console.warn('deactivate:', deactivates);
       formatQueryError(error);
       this.handleError(error, [...increase_rows, ...alias_rows, ...new_unit_rows, ...agreement_priority, ...deactivate_rows]);
       //todo: clear out some of the row status info
     });
  }

  errors = [];
  handleError(error, rows) {
    let uniq = new Set();
    rows.forEach(r => {
      if (!uniq.has(r.sheet_id)) {
        uniq.add(r.sheet_id);
        this.errors.push(r);
        let actual = this?.data?.data?.find(d => d.sheet_id === r.sheet_id);
        if (actual) {
          actual.upload_errors = actual.upload_errors ? [...actual.upload_errors, error] : [error];
          actual.in_progress = false;
          actual.epoch = actual.epoch + 1;
        }
      }
    });
    this.build_cache();
  }
  checkFinished() {
    console.log("checking for doneness", this.pending_rows, this.import_rows_in_progress);
    this.requestUpdate('import_rows_in_progress');
    if (!this.pending_data && this.import_rows_in_progress === 0 && !this.import_complete) {
      this.import_complete = true;
      this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { kind: 'complete', text: `import complete` } })); // TODO: undo
    }
  }


  handleUploadedIncreases(increases, aliases) {
    if (!increases || increases.length === 0) {
      console.warn("no increase results returned");
      return;
    }
    this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: `imported ${increases?.length} rows + ${aliases.length} aliases (${this.pending_increases.length} remaining)` } })); // TODO: undo
    increases.forEach(r => {
      let source_row = this.data.data.find(i => i.sheet_id === r.row_info);
      if (source_row) {
        console.log("UPSERTED EXISTING", source_row);
        source_row.epoch += 1;
        delete source_row.in_progress;
        this.selected.delete(source_row.row_id);
        if (this.selected.size === 0) {
          this.select_all = false;
        }
        source_row.matching_increases = [r];
        this.pending_rows.delete(`${source_row.sheet_id}::increase`);
      } else {
        console.error("UNABLED TO FIND EXISTING UPSERT SOURCE", r, increases);
      }
    });
    this.checkFinished();
  }

  firstUpdated() {
    super.firstUpdated();
    this.resizer = new ResizeObserver(async entries => {
      for (let entry of entries) {
        if (this.rect.height != entry.contentRect.height || this.rect.width != entry.contentRect.width) {
          this.rect = entry.contentRect;
        }
      }
    });

  }

  setRender(elem, visible) {
    if (elem && elem.data) {
      elem.data.onscreen = visible;
      elem.data.epoch += 1;
    }
  }


  dragEnter(e) {
    e.preventDefault();
    this.dragging = true;
  }
  dragLeave(e) {
    e.preventDefault();
    this.dragging = false;upload_file
  }

  dragDrop(e) {
    e.preventDefault();
    this.dragging = false;

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          var file = e.dataTransfer.items[i].getAsFile();

          window.requestIdleCallback(() => this.addFile(file));

        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        window.requestIdleCallback(() => this.addFile(e.dataTransfer.files[i]));
      }
    }
    // Pass event to removeDragData for cleanup
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to remove the drag data
      e.dataTransfer.items.clear();
    } else {
      // Use DataTransfer interface to remove the drag data
      e.dataTransfer.clearData();
    }
  }

  handleRouteEvent(name, evt) {
    switch(name) {
      case 'open-upload':
        //console.log("opening an upload", evt.detail);
        const arg = {
          data: evt.detail.data.data,
          hash: evt.detail.hash,
          header: evt.detail.data.header,
          source: { ...evt.detail.data.source, type: 'upload', id: evt.detail.id }
        }
        //console.log("calling handler with", arg);
        this.handleMDuesData(arg);
        this.progress = { ...this.progress, bytes_read: {finished: arg.data.length, total: arg.data.length }};
        this.progress = { ...this.progress, processed: { finished: arg.data.length, total: arg.data.length }};
        break;
      case 'new-upload':
        //console.log("opening file handler");
        this.renderRoot.getElementById('file_chooser').click();
        this.renderRoot.getElementById('upload_button').blur();
        break;
      default:
        console.warn("no such event", name);
    }
  }


  async handleMDuesData({data, hash, header, source}){
    this.data = {data, hash, render_queue: [], header, source, type: 'mdues', name: "Wage Survey", total_errors: 0, total_error_rows: 0, unit_ids: new Map(), unit_colors: new Map()};
    //console.log("GOT DATA FROM WORKER", header, data);
    this.display_limit = 8;///this.data.data.length;
    this.display_item = 0;
    this.header = header;
    this.build_cache();
    this.queueWork('fetch matches', null, () => this.fetchMatches());
    if (source?.type === 'file') this.queueWork('upload data', null, () => this.uploadSheet(this.data));
  }

  async uploadSheet(d) {
    let { data, hash, header, source, type, name } = d;
    console.log("uploadSheet", d, {data, header, source, type, name});
    // FIXME: update schema
    let upload = { year: this.period_year, name: source?.name, hash, format: type, status: {rows: data?.length, imported: 0}, data: {data, header, source, type, name}};
  
    let uploader = new EditImportFile(
      p => {
        console.log("saved sheet data", p);
      },  // data update function
      { changeMap: null },  //initial variables
      p => { // finalizing function
        console.log("finalized sheet", p);
        this.data.import_file = p;
      },
      (e, msgs) => { // error handler
        this.error = { data: this.data_property, error: e, msgs: msgs };
        console.error(this.error);
        this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
      });
    console.log("uploading", upload);
    uploader.save(upload, {});
  }

  async bgOpenXLSX(f) {
    this.data = { type: 'loading' };
    const data_handlers = {
      'mdues': data => this.handleMDuesData(data),
      'error': error => this.data = { 'type': 'error', error, message: `Error. Failed to load data: "${error}". Try again.` },
      'unknown': () => this.data = { 'type': 'unknown', message: `Unable to recognize data format. Try again.` }
    }
    const worker = await new this.XLSXworker(f,
      Comlink.proxy(async (t, p) => {
        this.progress = {...this.progress, ...p }
      }),
      Comlink.proxy(async (type, data) => {
        console.log("received data from XLSXworker", type, data);
        if (data_handlers[type]) {
          data_handlers[type](data);
        } else {
          this.data = { type: 'unknown', message: "Unable to load file. Try Again." };
        }
      }));
    console.log("BG WORKER LAUNCHED...", worker);
  }

  resetState() {
    this.files = [];
    this.data = null;
    this.selected = new Set();
    this.select_all = false;
    this.pending_searches = [];
    this.search_received = new Set();
    this.queue = [];
    this.pqueue = [];
    this.processing_queue = null;
    this.intersect = null;
    this.validating = new Set();

    this.pending_increases = [];
    this.pending_aliases = [];
    this.pending_agreements = [];
    this.pending_rows = new Set();

    this.import_count = 0;
    this.pending_searches = [];
    this.search_received = new Set();
    this.queue = [];
    this.pqueue = [];
    this.processing_queue = null;
    this.matched_rows = [];
    this.unmatched_rows = [];

    this.validate_queue = [];
  }

  addFile(f) {
    console.log("ADDING A NEW FILE", f);
    this.resetState();
    this.search_received = new Set();
    this.data = { type: 'loading' };
    this.progress = {};
    this.files = [f];
    this.files.forEach(f => {
      switch (f.type) {
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          this.bgOpenXLSX(f);
          this.import_count = this.import_count+1;
          break;
        case "text/tab-separated-values":
        case "text/csv":
          console.log("csv error", f);
          this.data = { 'type': 'error', message: `Only .xlsx files are currently recognized` };
          //this.openCSV(f);
          break;
        default:
          console.error("invalid file type:", f.type);
          this.data = { 'type': 'error', message: `Found [${f.type}] file, but only .xlsx files are currently recognized.` };
      }
    });
  }


  static get properties() {
    return {
      ...(super.properties),
      search_results: { type: Array },
      files: { type: Array },
      selected: { type: Object },
      select_all: { type: Boolean },
      filter: { type: Object },
      sort: { type: Object },
      data: { type: Object },
      dragging: { type: Boolean },
      active_user: { type: String },
      pending_searches: { type: Array },
      filter_menu: {type: String}
    };
  }
}

window.customElements.define('import-page', ImportPage);
export { ImportPage }
