import { LitElement, html, css } from 'lit';
import { MDuesPage } from '../components/mdues_page.js';
import '@material/mwc-icon';
import '@material/mwc-list';
import '@material/mwc-fab';
import '@material/mwc-button';
import '@material/mwc-dialog';
import '@material/mwc-textfield';
import { KaleForm, KaleTextField, KaleDate, KaleToggle, KaleEnum } from '../shared-components/form.js';
import { SearchPeriods, EditPeriod } from '../queries/queries.js';
import { MDuesResultList, MDuesListItem, NewItemDialog } from '../components/result_list.js';

const period_item_styles = css`
  :host {
    --item-primary-color: var(--period-color);
  }
  .alias-chip {
    --chip-background: var(--alias-color);
    --chip-color: white;
  }

  mwc-icon.lock {
    position: relative;
    top: 1px;
    font-size: 90%;
    margin-right: 6px;
  }


`;
export class PeriodItem extends MDuesListItem {
  static styles = [...super.styles, period_item_styles];
  static properties = { period: { type: Object } }
  static mutation_class = EditPeriod

  get locked() {
    return false;
  }
  renderHeaderName(period) { return html`${period.locked ? html`<mwc-icon class="lock">lock</mwc-icon>` : ''}${period?.year}` }
  renderItemBody({ locked = false, year = null, effective_date = null, stats = null } = {}) {
    return html`
            <div> <div class="info_item"><span class="info_label">year</span><span class="info_info">${year}</span></div> </div>
          <div> <div class="info_item"><span class="info_label">stat</span><span class="info_info">${stats ? stats.total_stat_members : 0}</span></div> </div>
          <div> <div class="info_item"><span class="info_label">adjusted (less negotiation)</span><span class="info_info">${stats ? stats.total_adjusted_members : 0}${stats && stats.period_total_adjusted_members_less_negotiation ? ` (${stats.period_total_adjusted_members_less_negotiation})` : 0}</span></div> </div>
          <div> <div class="info_item"><span class="info_label">average increase</span><span class="info_info">${(stats ? stats.average_increase/100 : 0).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1})}</span></div> </div>
          <div> <div class="info_item"><span class="info_label">complete</span><span class="info_info">${(stats ? stats.total_adjusted_members / stats.total_stat_members : 0).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1})}</span></div> </div>
          ${locked ? html`<div> <div class="info_item"><span class="info_label">locked</span><span class="info_info"><mwc-icon>lock</mwc-icon></span></div> </div>` : ''}
          
          `;
  }
  renderItemStatus({ status }) { return html`<div class="info_item"><span class="info_label">status</span><span class="info_info">${status ? status.desc : 'unk'}</span></div>` }
  renderEditForm(item) {
    return html`
      <div>
        <kale-textfield id="year" label="Year" field="year" .value=${item.year}></kale-textfield>
        <kale-toggle id="locked" label="Lock" field="locked" .value=${item.locked ?? false}></kale-toggle>
      </div>
    `;
  }
}

window.customElements.define('period-item', PeriodItem);




const period_list_styles = css`

`;

export class PeriodList extends MDuesResultList {
  static styles = [...super.styles, period_list_styles];
  static search_class = SearchPeriods;
  get mdues_period_required() { return false;}

  fab_title = "New Period"
  new_item_title = "New Period"

  renderItem(period) {
    return html`<period-item @update-list=${() => this.refresh()} .editing=${period.tempname !== undefined} .item=${period}></period-item>`;
  }
  
  add_item_saved(item) {
      this.results = [item, ...this.results];
      //this.requestUpdate("results");
  }  
}

window.customElements.define('period-list', PeriodList);

const periods_page_styles = css`
      :host { 
        background-color: white;
        --top-bar-color: var(--period-color);
      }
      .column {
        padding-top: 20px;
      }
        
`;

class PeriodsPage extends MDuesPage {
  static styles = [super.styles, periods_page_styles]
  static icon = "calendar_today"
  static default_title = "Periods"

  get period_dependent() { return false; }
  renderPage() {
    return html`
      <div class="column">
        <period-list class="refresh-list" .search=${this.search} @detail=${({ detail }) => this.detail = detail} .mdues_period=${this.mdp ? this.mdp.id : null}></period-list>
      </div>
        
        
     ${this.new_dialog_open? html`
        <newperiod-dialog @item-saved=${e=>this.handle_item_saved(e.detail)} 
        @new-period-dialog-closed=${e=>{{this.new_dialog_open=false; 
            console.log("PeriodPage ---", e, this)}}}>
            </newperiod-dialog>` : ''}
    `;   
  }
 
  static get properties() {
    return {
      ...(super.properties),
      search: { type: Object },
      detail: { type: Object },
      new_dialog_open: { type: Boolean }
    };
  }
  
  addNewPeriod() {
      if (!this.new_dialog_open)
        this.new_dialog_open = true;
  }
  
  handle_item_saved(item) {
     let list = this.renderRoot.querySelector('period-list');
     list?.add_item_saved(item);
  }  
  
  renderExtraItems() {
    return html`
      <mwc-button slot="actionItems" icon="add"  @click=${e => this.addNewPeriod()}>New Period&nbsp&nbsp</mwc-button>
      ${super.renderExtraItems()}
      `
  }  
}

window.customElements.define('period-page', PeriodsPage);
export { PeriodsPage }

const new_period_style = css`
  mwc-dialog {
    --mdc-dialog-scrim-color: rgba(0, 0, 0, 0.6);
    --mdc-dialog-min-width: 500px;
  }      
 
  kale-textfield {
     --kale-textfield-width: 500px;
  }
`;

class NewPeriodDialog extends NewItemDialog {
  static styles = [new_period_style]
  static properties = { ...super.properties, table_view: {type: Boolean}, item: { type: Object }, editing: { type: Boolean }, selectable: { type: Boolean }, expanded: { type: Boolean } }
  static mutation_class = EditPeriod;
  
  static get properties() {
    return {
      ...(super.properties),
      opened: {type: Boolean }
    };
  }

  // constructor() {
  //   super();
  //   this.item = {year: new Date().getFullYear()+1};
  // }
  //
  getDefaultData() {
    return {year: new Date().getFullYear()+1, locked: false};
  }
  
  dataUpdate(new_data) {
    console.log("Data Update", new_data);
    this.item = new_data;
  }
  
  finalUpdate(new_data) {
    this.dispatchEvent(new CustomEvent('item-saved', { bubbles: true, composed: true, detail: new_data }));
    this.saveComplete(Object.keys(new_data));
    let f = () => {
      console.log("Refreshing Periods");
      window.set_current_period(window.get_periods()[0], this);
    }
    console.log("calling refresh_periods", f);
    window.refresh_periods(f);
  }
  
  updateList() {
    this.dispatchEvent(new CustomEvent('update-list', { bubbles: true, composed: true, detail: this.item }))
  }
  
  
  get mutation_instance() {
    if (!this.constructor.mutation_class) 
        return null;
    
    if (!this._mutation) 
        this._mutation = new (this.constructor.mutation_class)(
            (item) => this.dataUpdate(item),
            { changeMap: null },
            (item) => this.finalUpdate(item),
            (err) => console.error(err) //FIXME: more error checking/alerts
       );
    
    return this._mutation;
  }
  
  save_impl(data) {
    data = {...this.getDefaultData(), ...data};
    const mut = this.mutation_instance;
    mut.save(data);  
    console.log("Saving...", data);
  }  

  
  
  render()  {
    let year = this.item?.year ?? '';
    return  html` 
      <mwc-dialog id="newPeriodDialog" heading="New Period" open title="New Period"  
      @closed=${e=>{this.dispatchEvent(new CustomEvent('new-period-dialog-closed', { bubbles: true, composed: true, detail: null })) }}>
        <div>
        <kale-textfield id="periodYear" label="Year" field="year" .value=${year} .default=${this.getDefaultData().year} ></kale-textfield>
        </div>
        
        <mwc-button
            id="primary-action-button"
            slot="primaryAction"
            @click=${e => this.save()}
            dialogAction="periodsaved">
            Save
        </mwc-button>
      
        <mwc-button
            slot="secondaryAction"
            dialogAction="close">
            Cancel
        </mwc-button>
      </mwc-dialog>`
      ;
  }

}

window.customElements.define('newperiod-dialog', NewPeriodDialog);
export { NewPeriodDialog }
